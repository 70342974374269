.header{
    width:100%;
    height:68px;
    background: #FFFFFF;
    position: sticky;
    top:0px;
    z-index: 10000;
    box-shadow: 0px 2px 6px rgba(37, 37, 37, 0.16);
}
.headerBox{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 92%;
    margin: auto;
}
.headerLogo{
    width:158px;
    height:37px;
}
.headerBox > button{
    background: #002B4F;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 4px 20px;
    gap: 10px;
    line-height: 36px;
}
@media screen and (max-width:700px)
{
    .header{
        width:100%;
        height:58px;
        background: #FFFFFF;
    }
    .headerBox{
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 91%;
        margin: auto;
    }
    .headerLogo{
        width: 100px;
        height: 28px;
        object-fit: contain;
    }
    .headerBox > button{
        background: #002B4F;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 6px 16px;
    gap: 10px;
    line-height: 134.5%;
    }
}
@media screen and (max-width:350px){
    .headerBox > button{
    font-size: 12px;
    padding: 6px 9px;
    }
    .header{
        height:48px;

    }
}