.souravDadaImage {
  background-image: linear-gradient(to bottom, transparent, rgba(12, 28, 69, 1)),
    url("../../assets/Images/sourav-dada.png");
  /* width: 65%;
  height: 58vh;
  margin-left: 25%;
  background-size: cover;
  color: white;
  position: absolute;
  bottom: 100px; */
  width: 56%;
    height: 51vh;
    /* margin-left: 25%; */
    background-size: cover;
    color: white;
    position: absolute;
    z-index: -1;
    /* left: 85px; */
    right: 0px;
    bottom: 100px;
  -webkit-transition: opacity 800ms ease-out, visibility 800ms ease-out;
  transition: background-size 1800ms cubic-bezier(0, 0.03, 1, 0.99);
}

@keyframes fadeout {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.dadaFadeOut {
  animation: fadeout 500ms;
  opacity: 0;
}

/* @media screen and (max-height: 800px) {
  .souravDadaImage {
    height: 55vh;
  }
}
 */

 @media screen and (max-height: 700px) {
  .souravDadaImage {
    height: 53vh;
  }
}

@media screen and (max-height: 650px) {
  .souravDadaImage {
    height: 48vh;
  }
}

@media screen and (max-height: 550px) {
  .souravDadaImage {
    height: 40vh;
  }
}

