@keyframes fadeout {
  0% {
    opacity: 1;
    height: auto;
  }
  99% {
    opacity: 1;
    height: auto;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

.hideInfo {
  animation: fadeout 500ms ease-out;
  opacity: 0;
  height: 0;
}

.textStyleMob {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #33BDEC;
  display: flex;
  align-items: center;
  color: #33BDEC;
  padding: 4px 0px;
}

.imgStyleMob{
  height: 12.54px;
  width: 12.54px;
  margin-right: 6.7px;
}


div{
  posititon: relative;
}
.js-nametag{
  position: absolute;
}
.js-nametag:nth-child(1){
  animation-name: fade;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-direction: alternate;  
}


.js-nametag:nth-child(2){
  animation-name: fade;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-delay: 2s;

}

.js-nametag:nth-child(3){
  animation-name: fade;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-delay: 4s;
}


/* .js-nametag:nth-child(4){
  animation-name: fade;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-direction: alternate;
}
 */


@keyframes fade{
    0%,50% {
      opacity: 0;
}
    100%{
      opacity: 1;
  }
}
  .nav-mobile-view{
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    /* width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #0C1C45;
    top:0; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #0C1C45;
    top: 0;
    background: white;
    margin-left: -16px;
    height: auto;
    padding: 0px 16px;
    height: 54px;

  }
  .nav-lang-box{
    display: flex;
    border: 1px solid #009AE0;
    border-radius: 8px;
    padding: 5px 9px;
    gap: 6px;
  }
  .nav-lang-box > img{
    width: 20px;
    height: 20px;
  }
  .nav-lang-box > p{
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    font-weight: 300;
  }

  .live-now-mobile{
        background: #002b4f;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    border-radius: 8px;
    padding: 6px 16px;
    gap: 10px;
    line-height: 134.5%;
  }
  .nav-lang-box > img {
    width: 100px;
    height: 28px;
  }

  .modal-growth-webinar{
    position: absolute;
    width: 90%;
    align-self: center;
    background: #FFFFFF;
    border-radius: 16px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.92em;
    padding-bottom: 0.92em;
    align-items: center;
    top: 0.6em;
    box-shadow: 2px 2px 12px rgba(255, 255, 255, 0.24);
    animation: fadeIn 1s, slideIn .8s linear;
  }

  .poster-modal{
    position: fixed;
    z-index: 1000;
    top: 19vh;
    left: 3%;
    right: 3%;
    animation: fadeIn 1s, slideIn .8s linear;
  }
  .poster-modal-close{
    border-radius: 56%;
    width: 7%;
    height: 3vh;
    position: absolute;
    right: -2%;
    top: -1.1vh;
    animation: none;
  }
  .modal-img-text-wrp{
    display: flex;
    flex-direction: column
  }
  
  .growth-webinar-text{
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding-left: 1em;
    font-family: CircularStd;
  }

  .announcement-text{
    font-weight: 700;
    font-size: 16px;
    color: #0C1C45;
    padding-left: 1em;
    font-family: CircularStd;
  }

  .blink {
    width: 1em;
    height: 1em;
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .live-now{
    width: 44px;
    height: 18px;
  }

  .live-text{
    font-weight: bold;
    font-size: 14px;
    color: #FF4C5C;
    font-style: normal;
    letter-spacing: 1px;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(400px);
      animation-timing-function: ease-out;
    }
    60% {
      transform: translateY(-30px);
      animation-timing-function: ease-in;
    }
    80% {
      transform: translateY(10px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateY(0px);
      animation-timing-function: ease-in;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .redLive{
    background-color: red;
    border-radius: 52%;
    height: 11px;
    width:11px;
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  .poster-img{
    width: 100%;
    height: 62vh;
  }