.join-whatsapp-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap:  24px;
    padding: 24px;
    background-color: #F6F7FB;
    border-radius: 4px;
}

.join-whatsapp-text {
    color: #0C1C45;
}

.join-whatsapp-button {
    background-color: #25D366;
    color: #FFFFFF;
    padding: 12px 24px;
    border-radius: 4px;
    width: 300px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.join-whatsapp-img {
    height: 18px;
    width: 18px;
}