.textStyle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #33BDEC;
    display: flex;
    align-items: center;
    line-height: 27px;
    color: #33BDEC;
    width: 100%;
    padding: 10px 0px;
  }
  .nav-container{
    width: 100%;
    height: 6vh;
    background: #071436;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .content-box{
    display:flex;
    gap:58px;

  }
  .content-box > p{
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

display: flex;
align-items: center;

color: #FFFFFF;
  }
  .nav-lang-box{
    display: flex;
    border: 1px solid #009AE0;
    border-radius: 8px;
    padding: 5px 9px;
    gap: 6px;
    cursor: pointer;
  }
  .nav-lang-box > img{
    width: 20px;
    height: 20px;
  }
  .nav-lang-box > p{
    font-style: normal;
    /* font-weight: 450; */
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    font-weight: 300;
  }
  .featureContainer{
    padding-top: 18px;
  }

  .imgContainer{
    bottom: 0px;
    position: fixed;
    margin-left: 10%;
  }

  .imgContainerNew{
    bottom: -120px;
    left: 20%;
  }
  @media screen and (max-width: 1000px) {
    .imgContainerNew{
      left: 25%;
    }
  }
  @media screen and (max-height: 900px) {
    .imgContainerNew{
      max-width: 300px;
      height: 65vh;
    }
  }

  @media screen and (max-width: 1200px) {
    .imgContainerNew{
      width: 300px;
      height: 65vh;
    }
  }
  @media screen and (max-width: 1059px) and (max-height: 699px) {
    .imgContainerNew{
      width: 250px;
      height: 58vh;
    }
  }
  .nameStyle{
    width: 210px;
    height: 50px;
    bottom: 45px;
    position: fixed;
    left: 8%;
  }
  
  .imgStyle{
    width: 23.84px;
    height: 23.84px;
    margin-right: 6.73px;
  }
