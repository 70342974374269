/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CircularStd";
  src: url(./assets/fonts/circularStd-medium.ttf);
  font-weight: bold;
}
@font-face {
  font-family: "CircularStd";
  src: url(./assets/fonts/CircularStd-Book.ttf);
  font-weight: normal;
}

.bindra-img {
  height: 550px;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

body {
  background-color: #0c1c45;
  color: #fff;
  scroll-behavior: smooth;
}

hr {
  width: 199px;
  bottom: 24px;
  right: 51px;
}

input {
  text-indent: 10px;
  color: #009ae0;
  font-size: 16px;
  font-weight: bold;
}

.tick {
  filter: brightness(0) invert(1);
}

/* select:disabled {
  background-color: #fff;
  filter: brightness(0) invert(1);
} */

select option {
  background-color: #0e2256;
  color: white;
}

select option:hover {
  background-color: #0c1c45;
}
.conversationContainer {
  position: relative;

  z-index: 999;
  background: linear-gradient(0deg, #0c1c45, transparent);
  scrollbar-gutter: stable;
  margin-top: 20vh;
  animation: appear 1.5s ease-in-out;
}
.conversationContainer:last-child {
  padding-bottom: 10px;
}

.conversationContainerWithScreenshots {
  padding: 0px 1.5rem;
}

.conversation {
  position: relative;
  z-index: 999;
  animation: appear 1s ease-in-out;
}

.conversationFirst {
  position: relative;
  z-index: 999;
  animation: appear 1s ease-in-out;
}

.conversation-simple {
  position: relative;
  z-index: 999;
  animation: appear 0.7s ease-in-out;
}

.slick-prev {
  display: none !important;
}

.slick-next {
  display: none !important;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  80% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 
::-webkit-scrollbar {
  width: 10px;
} */

/* .dropdownModal::-webkit-scrollbar {
  width: 20px;
  background-color: #45c5ff;
}

.dropdownModal::-webkit-scrollbar-thumb {
  background: #0c1c45;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
} */

.dropdownModal::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #45c5ff, #0c1c45 1px, #0c1c45 0, #45c5ff);
}

.conversationContainerParent::-webkit-scrollbar {
  width: 0;
}

.androidScreen {
  box-shadow: -20px -8px 20px 6px #0c1c45;
  background: linear-gradient(184deg, #0c1c45, #0c1c45);
  top: 31rem;
  height: 300px;
  position: absolute;
  width: 100%;
}

.conversationContainerParent {
  /* max-width: 21rem; */
  width: 100%;
  margin-left: -1.75rem;
  overscroll-behavior-y: contain;
}

/* For .androidScreen */
.sc-cxpSdN.hygwfp > svg {
  filter: invert(1);
}

.sc-cxpSdN.hygwfp {
  background: #0c1c45;
  box-shadow: none;
}

.multiSelectCheck {
  filter: invert(35%) sepia(99%) saturate(676%) hue-rotate(161deg)
    brightness(110%) contrast(109%);
}

@keyframes fadeout {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.AndroidMobile > .slick-slider {
  animation: fadein 2000ms;
  opacity: 1;
}

.AndroidMobile {
  margin-top: 12px;
}

@media screen and (max-height: 700px) {
  .conversationContainer {
    margin-top: 12vh;
  }
}

@media screen and (max-height: 800px) {
  .conversationContainer {
    margin-top: 7vh;
  }
}

.heading {
  /* margin-bottom: 10px; */
  top: 30px;
  z-index: 9999;
  background: #0c1c45;
  width: 100%;
  height: 190px;
}

.playstoreDisplayName {
  width: 200px !important;
}

@media screen and (min-height: 720px) {
  .heading {
    height: 160px;
  }
}

@media screen and (max-width: 800px) {
  .heading {
    height: 155px;
  }
  .desktopViewAndroid {
    width: 90%;
  }
}
@media screen and (max-height: 720px) {
  .heading {
    height: 145px;
  }
}

.dropdownModal {
  margin: 10rem 0;
  z-index: 999999;
  max-height: 85vh;
}

@media screen and (min-width: 380px) {
  .androidCoachingName {
    top: 53px;
    left: 21px;
    font-size: 13px;
  }
}

@media screen and (max-width: 380px) {
  .androidCoachingName {
    top: 45px;
    left: 15px;
    font-size: 13px;
  }
  .playstoreContainer {
    top: 4.5rem;
    left: 2rem;
  }
}

@media screen and (max-width: 360px) {
  .androidCoachingName {
    top: 45px;
    left: 13px;
    font-size: 13px;
  }
  .playstoreContainer {
    top: 4rem;
    left: 1.5rem;
  }
}

@media screen and (max-width: 340px) {
  .androidCoachingName {
    top: 35px;
    left: -25px;
    font-size: 12px;
  }
  .playstoreContainer {
    top: 4rem;
    left: 1.5rem;
  }
  .playstoreIcon {
    height: 3rem;
    width: 3rem;
  }
  .playstoreDisplayName {
    font-size: 12px;
  }
}

.eduacationMedia {
  margin-left: 8px;
  font-size: 10px;
}

@media screen and (min-width: 720px) {
  .dropdownModal {
    height: 260px;
    overflow-y: overlay;
    width: 39%;
    margin-right: 7rem;
    display: block;

    top: 13rem;
  }
}

/* KnowMore Section Styling */

.knowMoreContainer {
  background-color: #fff;
}

.KnowMore__section {
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.KnowMore__section h3 {
  font-style: normal;
  font-weight: bold;
  margin: 5rem 0 2rem 0rem;
  font-size: 24px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.87);
}
.KnowMore__section h4 {
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: 450;
  font-size: 17px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin: 1rem 0 2rem 0;
}
.KnowMore__section__images {
  width: 92%;
}
.KnowMore__section__images img {
  width: 100%;
}

@media (min-width: 944px) {
  .KnowMore__section {
    display: flex;
    margin-top: 0;
    margin-bottom: 10rem;
  }
  .KnowMore__section h3 {
    font-size: 36px;
    line-height: 56px;
  }
  .KnowMore__section__images {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
  }
  .KnowMore__section__images__singleImageWithText {
    width: 28%;
  }
}

.upArrow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.upArrowParent {
  left: 50%;

  margin-left: -18.5px; /* doing transform in X with half  the width of element */

  z-index: 100000;
}

.conversationContainerParent {
  transition: max-height 0.6s cubic-bezier(0, 0.96, 0.38, 1.24);
}

.upArrowParent {
  transition: transform 0.6s cubic-bezier(0, 0.96, 0.38, 1.24);
  -webkit-transition: transform 0.6s cubic-bezier(0, 0.96, 0.38, 1.24);
}

.loaderFontSize {
  font-size: 12px;
}

.question {
  position: relative;
}
.question::after {
  content: "";
  position: absolute;
  border-top: 12px solid #009ae0;
  border-left: 10px solid #009ae000;
  border-radius: 4px 0 0 4px;
  width: 0;
  top: 0;
  height: 0;
  left: -8px;
}

.questionArrow {
  position: absolute;
  float: left;
  left: -11px;
  top: 0px;
}

.answerArrow {
  bottom: 20px;
  left: auto;
  right: 4px;
  float: right;
}

.answerArrow::after {
  transform: scaleX(-1);
  content: "";
  position: absolute;
  border-top: 14px solid #ffffff;
  border-left: 12px solid #009ae000;
  border-radius: 4px 0 0 4px;
  width: 0;
  bottom: 26px;
  height: 0;
  right: -9px;
}

.answerArrow.multiSelectArrow::after {
  transform: scaleX(-1);
  content: "";
  position: absolute;
  border-top: 14px solid #ffffff;
  border-left: 12px solid #009ae000;
  border-radius: 4px 0 0 4px;
  width: 0;
  bottom: 30px;
  height: 0;
  right: -13px;
}

.answerArrow.multiSelectArrow {
  position: relative;
  float: none;
  top: 0px;
}

.infoIconContiner {
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-column-gap: 5px;
}
