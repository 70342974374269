.logoContainer {
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  bottom: 3rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
}

.iconsContainer {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 15px;
  width: 75%;
  bottom: 25px;
}
.iconsContainer img {
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 600px) {
  .logo {
    margin-left: 35.82px;
    margin-top: 42.56px;
  }

  .thank_you {
    position: relative;
    word-break: break-word;
    left: 34px;
    top: 30px;
    font-family: CircularStd;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 42px;
    color: #45c5ff;
  }

  .thank_you_text {
    position: relative;
    width: 346px;
    word-break: break-word;
    height: 54px;
    left: 36px;
    top: 50px;
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
  }

  .rectangle_1 {
    position: absolute;
    width: 100%;
    height: 528px;
    left: 0px;
    top: 258px;

    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 154, 224, 0.15);
  }

  .text_till_then {
    position: absolute;
    width: 93px;
    height: 24px;
    left: 161px;
    top: 56px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #333333;
  }

  .text_make_your_app {
    position: absolute;
    width: 366px;
    height: 52px;
    left: 25px;
    top: 96px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    text-align: center;
    text-transform: capitalize;

    color: rgba(0, 0, 0, 0.8);
  }

  .text_super_app {
    position: absolute;
    width: 318px;
    height: 40px;
    left: 49px;
    top: 168px;
    text-align: center;
    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    display: flex;
    align-items: center;

    /* Sub Text */

    color: #666666;
  }

  .app-image {
    position: absolute;
    width: 414px;
    height: 200px;
    left: 1px;
    top: 228px;

    border-radius: 10px 10px 0px 0px;
  }

  .get-app-button {
    position: absolute;
    height: 48px;
    width: 80%;
    left: 10%;
    top: 420px;
    background: #009ae0;
    border-radius: 8px;
  }

  .text_still_not_impressed {
    position: absolute;
    width: 212px;
    height: 24px;
    left: 103px;
    top: 840px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #ffffff;
  }

  .text_customer_review {
    position: absolute;
    width: 366px;
    height: 52px;
    left: 26px;
    top: 880px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    text-align: center;
    text-transform: capitalize;

    color: #ffffff;
  }

  .rectangle_2 {
    position: absolute;
    width: 100%;
    height: 642px;
    left: 0px;
    top: 1437px;

    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 154, 224, 0.15);
  }

  .text_need_assurance {
    position: absolute;
    width: 233px;
    height: 24px;
    left: 90px;
    top: 31px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #009ae0;
  }

  .text_media_appreciation {
    position: absolute;
    width: 366px;
    height: 52px;
    left: 24px;
    top: 71px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    text-align: center;
    text-transform: capitalize;

    color: rgba(0, 0, 0, 0.8);
  }

  /* .logo-colored {
    margin-bottom: 40px;
  } */

  .text_contact_us {
    margin-top: 30px;
  }

  .text_request_call {
    position: absolute;
    width: 108px;
    height: 21px;
    left: 153px;
    top: 890px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    text-transform: capitalize;

    color: #ffffff;
  }

  .facebook {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 79px;
    top: 935px;
  }

  .instagram {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 135px;
    top: 935px;
  }

  .youtube {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 191px;
    top: 935px;
  }

  .linkedin {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 247px;
    top: 935px;
  }

  .twitter {
    width: 32px;
    height: 32px;
    margin-left: 303px;
    margin-top: 843px;
  }
}

@media only screen and (min-width: 600px) {
  .thank_you_screen {
    align-items: center;
    text-align: center;
  }

  .knowMoreContainer {
    margin-top: 50px;
  }

  .logo-colored {
    width: 150px;
    margin-left: 60px;
  }

  .footer {
    flex-direction: row;
  }

  .logoContainer {
    flex-direction: row;
    width: 80%;
    bottom: 80px;
    justify-content: space-between;
  }
  .iconsContainer {
    margin-bottom: 0;
    bottom: 80px;
    width: 30%;
  }

  .logo {
    margin-left: 35.82px;
    margin-top: 42.56px;
  }

  .thank_you {
    width: 100%;
    height: 68px;
    margin-top: 30px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 130%;
    /* identical to box height, or 68px */

    text-align: center;

    color: #45c5ff;
  }

  .thank_you_text {
    margin-bottom: 30px;
    width: 100%;
    height: 33px;
    margin-top: 20px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 150%;
    /* identical to box height, or 33px */

    text-align: center;

    color: #ffffff;
  }

  .rectangle_1 {
    position: absolute;
    width: 100%;
    height: 776px;
    top: 340px;

    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 154, 224, 0.15);
  }

  .text_till_then {
    width: 100%;
    height: 33px;
    margin-top: 93px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 150%;
    /* identical to box height, or 33px */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #333333;
  }

  .text_make_your_app {
    width: 60%;
    margin: 0 auto;
    height: 114px;
    margin-top: 23px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 130%;
    /* or 57px */

    text-align: center;
    text-transform: capitalize;

    color: rgba(0, 0, 0, 0.8);
  }

  .text_super_app {
    width: 100%;
    height: 20px;
    margin-top: 32px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 91%;
    /* identical to box height, or 91% */

    /* Sub Text */

    color: #666666;
  }

  .app-image {
    width: 25%;
    height: 219px;
    margin: 0 auto;
    margin-top: 50px;
  }

  .get-app-button {
    height: 48px;
    top: 972px;

    background: #009ae0;
    border-radius: 8px;
  }

  .text_still_not_impressed {
    width: 100%;
    height: 33px;
    margin-top: 900px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 150%;
    /* identical to box height, or 33px */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #ffffff;
  }

  .text_customer_review {
    width: 60%;
    margin: 0 auto;
    height: 114px;
    margin-top: 24px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 130%;
    /* or 57px */

    text-align: center;
    text-transform: capitalize;

    color: rgba(255, 255, 255, 0.8);
  }

  .rectangle_2 {
    position: absolute;
    width: 100%;
    height: 1401px;
    top: 1970px;

    background: #ffffff;
  }

  .text_need_assurance {
    width: 100%;
    height: 33px;
    margin-top: 63px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 150%;
    /* identical to box height, or 33px */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #009ae0;
  }

  .text_media_appreciation {
    width: 60%;
    margin: 0 auto;
    height: 114px;
    margin-top: 24px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 130%;
    /* or 57px */

    text-align: center;
    align-items: center;
    text-transform: capitalize;

    color: rgba(0, 0, 0, 0.8);
  }

  .logo-colored {
    /* position: absolute;
    width: 172.32px;
    height: 40px;
    left: 135px;
    top: 1146px; */
    /* padding-bottom: 50px; */
  }

  .text_contact_us {
    /* position: absolute;
    width: 92px;
    height: 23px;
    margin-left: 40%;
    top: 1150px;
    margin-bottom: 40px;
    padding-bottom: 40px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%; */
    /* or 23px */

    /* text-align: center;
    text-transform: capitalize;

    color: #ffffff; */
  }

  .text_request_call {
    position: absolute;
    width: 121px;
    height: 23px;
    margin-left: 50%;
    top: 1150px;

    font-family: CircularStd;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    text-align: center;
    text-transform: capitalize;

    color: #ffffff;
  }

  .facebook {
    position: absolute;
    width: 32px;
    height: 32px;
    margin-left: 70%;
    top: 1150px;
  }

  .instagram {
    position: absolute;
    width: 32px;
    height: 32px;
    margin-left: 74%;
    top: 1150px;
  }

  .youtube {
    position: absolute;
    width: 32px;
    height: 32px;
    margin-left: 78%;
    top: 1150px;
  }

  .linkedin {
    position: absolute;
    width: 32px;
    height: 32px;
    margin-left: 82%;
    top: 1150px;
  }

  .twitter {
    width: 32px;
    height: 32px;
    margin-left: 86%;
    margin-top: 867px;
  }

  .carousalContainer{
    margin-top: 40px;
  }
}
