.homeScreen {
  height: calc(100vh - 20px);
}

@keyframes slide {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
    margin-top: 0;
  }
}
.convo-slide {
  animation: slide 0.5s forwards;
}
