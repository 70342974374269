.radar {
  margin: 0 auto;
  position: relative;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  overflow: hidden;
  background: #0c1c45;
  background: repeating-radial-gradient(
      transparent,
      transparent 35.2941176471px,
      #fff 37.2941176471px
    ),
    100%, #0c1c45;
}

.scanner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: conic-gradient(
    from 90deg at 50% 50%,
    rgba(12, 28, 69, 0.5) 0% 65%,
    rgba(255, 255, 255, 0.5)
  );
  -webkit-animation: rotation 4s infinite linear;
  animation: rotation 4s infinite linear;
}

.target {
  position: absolute;
  opacity: 0;
  height: 25px;
  width: 25px;
  background: #fff;
  border-radius: 50%;
  left: 80%;
  bottom: 40%;
  -webkit-animation: pulse 4s infinite linear;
  animation: pulse 4s infinite linear;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.target:before,
.target:after {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.target:before {
  content: "";
  opacity: 0;
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 50%;
  background-color: #fff;
  -webkit-animation: pulse-ring 4s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  animation: pulse-ring 4s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.target:after {
  content: "";
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  animation: pulse-dot 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
.target:nth-child(2) {
  left: 70%;
  top: 55%;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.target:nth-child(2):before,
.target:nth-child(2):after {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.target:nth-child(3) {
  left: 42%;
  bottom: 23%;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.target:nth-child(3):before,
.target:nth-child(3):after {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.target:nth-child(4) {
  left: 22%;
  top: 23%;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.target:nth-child(4):before,
.target:nth-child(4):after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.target:nth-child(5) {
  left: 73%;
  top: 20%;
  -webkit-animation-delay: 3.4s;
  animation-delay: 3.4s;
}
.target:nth-child(5):before,
.target:nth-child(5):after {
  -webkit-animation-delay: 3.4s;
  animation-delay: 3.4s;
}

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes pulse {
  0% {
    opactiy: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    opactiy: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-ring {
  0% {
    opacity: 0;
    transform: scale(0.33);
  }
  1% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-ring {
  0% {
    opacity: 0;
    transform: scale(0.33);
  }
  1% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
