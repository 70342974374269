.toast {
    position: fixed;
    top: 4rem;
    right: 20px;
    padding: 5px 15px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    z-index: 90000;
    background-color: #333;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideIn 1s forwards;
}

.toast.success {
    background-color: green;
}

.toast.error {
    background-color: red;
}

.toast.info {
    background-color: blue;
}

.toast.warning {
    background-color: orange;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.toast.hide {
    animation: slideOut 0.5s forwards;
}

@media (max-width: 768px) {
    .toast {
        top: 3rem;
        right: 1rem;
        padding: 2.5px 10px;
        font-size: 1rem;
    }
}